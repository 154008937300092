@import "../../styles/variables";

.payment-page-container {
  background-image: url("../../assets/pattern.webp");
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(6, 58, 79, 1);
  background-origin: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  background-origin: border-box;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: 75%;

  @media screen and (max-width: 600px) {
    background-size: 100%;
  }

  .main {
    background-color: $color-white;
    max-width: 560px;
    width: 90%;
    margin: 40px auto 0 auto;
    border-radius: 24px;

    .top-header {
      width: 100%;
      background-size: cover;
      background-position: center;
      height: 110px;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 16px 0;
    }

    .info {
      background: rgba(0, 184, 194, 0.05);
      h3 {
        margin: 0;
        margin-bottom: 22px;
      }
    }
  }
  .footer {
    margin: 32px auto;
  }
}

.content-body-padding {
  padding: 24px;
}

.page-contents {
  background: #fbfcfd;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid #e9eff2;

  .name-container-flex{
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .name-field{
      width: 49%;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.ant-input {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Inter, sans-serif !important;
  font-size: 16px;
  height: 46px;
}

.ant-picker-input{
  font-size: 16px;
}

.ant-select-selection-item{
  font-size: 16px;
}

.ant-space {
  width: 100%;
}

.ant-space-item {
  width: 100% !important;
  padding: 10px 8px;
}

.ant-radio-wrapper {
  width: 100%;

  span:not(.ant-radio, .ignore-span) {
    width: 100%;
  }
}

.ant-radio {
  width: 16px;
}

.ant-space-item:hover {
  background-color: #f2fbfc;
  border-radius: 8px;
}

.page-description-content {
  color: #4d4d4d !important;
  font-size: 13px !important;
}

.page-title {
  font-size: 20px !important;
  color: #063a4f !important;
}

.page-divider {
  width: 50px;
  height: 1px;
  background-color: #d9e5ea;
  margin-bottom: 20px;
  margin-top: 20px;
}

.loading-container {
  padding: 40px 0px;
}

.default-top-bg {
  background-color: #00b8c2;
}

.merchant-logo-inner {
  background: linear-gradient(147.87deg, #f0aa22 -8.91%, #bd10e0 99.52%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchant-logo-outer {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 4px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
