@import "./styles/variables";

body {
  margin: 0;
  background-color: #e5e5e5;
  width: 100%;
  min-height: 100vh;
  font-family: "Inter", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: Inter, sans-serif !important;
}

.not-found-main {
  width: 80%;
  margin: 100px auto 0px auto;
  max-width: 720px;
  border: 1px solid #e9eff2;
}

.not-found-main {
  background-color: #fff;
  border-radius: 32px;
}

.not-found-header {
  background: rgba(0, 184, 194, 0.05);
  padding: 20px 20px;
  border-radius: 32px 32px 30% 30%;
}

.bgwhite {
  background-color: #fff;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-grey {
  color: rgba(0, 0, 0, 0.5);
}

.form-label-style{
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .ant-radio-group {
    display: flex !important;
    flex-direction: column !important;
    .ant-radio-wrapper {
      margin-bottom: 10px;
    }
  }
}

.mb {
  @for $var from 0 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-bottom: $var * 2px !important;
    }
  }
}

.mt {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-top: $var * 2px !important;
    }
  }
}

.ml {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-left: $var * 2px;
    }
  }
}

.pl {
  @for $var from 1 through 50 {
    &-#{$var} {
      // width: $start-width * $var;
      padding-left: $var * 2px;
    }
  }
}

.pr {
  @for $var from 1 through 50 {
    &-#{$var} {
      // width: $start-width * $var;
      padding-right: $var * 2px;
    }
  }
}
.fnt-size {
  @for $var from 1 through 40 {
    &-#{$var} {
      // width: $start-width * $var;
      font-size: $var * 1px !important;
    }
  }
}

.fnt-weight-400 {
  font-weight: 400 !important;
}

.mr {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-right: $var * 2px;
    }
  }
}

.pb {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      padding-bottom: $var * 2px !important;
    }
  }
}

.my {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-top: $var * 2px;
      margin-bottom: $var * 2px;
    }
  }
}

.ant-btn-primary {
  background: linear-gradient(175.31deg, rgb(240, 170, 34) -8.91%, rgb(240, 88, 34) 99.52%) rgb(204, 139, 14) !important;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.by-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
  opacity: 0.4;
  margin-right: 6px;
}

a {
  color: #00b8c2 !important;
}

.ant-radio-group {
  width: 100% !important;
}

.font-size {
  @for $var from 1 through 100 {
    &-#{$var} {
      // width: $start-width * $var;
      font-size: $var * 1px !important;
    }
  }
}

.text-grey2 {
  color: #666666 !important;
}

.text-white {
  color: #fff;
}

.font-weight-700 {
  font-weight: 700;
}

.position-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-bottom-left {
  display: flex;
  align-items: flex-end;
}

.h-100 {
  height: 100%;
}

.ant-btn {
  padding: 20px 0px !important;
}

.frame-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #343434;
  z-index: 100;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.success-wrapper {
  width: 70%;
  margin: 0 auto;
  max-width: 400px;
  background-color: #fff !important;
  padding-top: 15px;
  padding-bottom: 20px;
}

.free-entry-container{
  .ant-input{
    height: 58px !important;
    font-size: 24px;
    font-weight: 700;
  }

  .ant-input-group-addon{
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }
}
