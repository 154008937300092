h1 {
  color: red;
}

.notfounImage {
  width: 200px;
  object-fit: contain;
}

.not-found-content {
  background-color: #fff;
  padding: 0px 20px 40px 20px;
  border-radius: 0 32px;
  border-bottom-right-radius: 32px;
  font-family: Inter, sans-serif !important;

  h1 {
    font-weight: 900;
    font-size: 100px;
    font-family: Inter, sans-serif !important;
  }
}

@media screen and (max-width: 720px) {
  .notfounImager {
    width: 120px;
  }

  .not-found-content {
    h1 {
      font-size: 75px;
    }
  }
}

.not-found-action-button {
  width: 60%;
  max-width: 300px;
}
