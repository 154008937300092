@import '../../styles/variables';

.pending-authorization-page-container{
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(6, 58, 79, 1);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: center;
    align-items: center;

    .pending-authorization-page{
        display: flex;
        max-width: 500px;
        min-height: 100px;
        padding: 28px 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        border-bottom: 1px solid rgba(180, 203, 213, 0.40);
        background: #F9FBFD;

        @media screen and (max-width: 767px) {
            margin: 40px 20px;
        }

        .title-subtitle-container{
            display: flex;
            flex-direction: column;

            .title{
                font-size: 20px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
            }

            .subtitle{
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 8px;
            }
        }

        .transfer-details-container{
            display: flex;
            padding: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            border: 1px solid rgba(0, 184, 194, 0.20);
            background: #FBFEFF;
            margin-top: 16px;

            .transfer-instruction{
                font-size: 20px;
                font-weight: 600;
                color: rgba(6, 58, 79, 1);
                margin-top: 11px;
                max-width: 250px;
                text-align: center;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .account-details-container{
                display: flex;
                padding: 8px 16px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background:rgba(180, 203, 213, 0.20);
                width: 90%;
                margin-top: 16px;
                gap: 16px;

                .title-value-container{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
        
                    .title{
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                    }
        
                    .value{
                        font-size: 16px;
                        font-weight: 700;
                        color: rgba(0, 0, 0, 1);
                    }

                    .value-icon{
                        display: flex;
                        align-items: center;
                    }

                    .copy-btn{
                        border: none !important;
                        border-color: transparent !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: none;
                        outline: none !important;
                        box-shadow: none !important;

                        &:focus{
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }

            .transfer-note{
                font-size: 14px;
                font-weight: 400;
                margin-top: 16px;
                line-height: 150%;
                text-align: center;
            }

            .footer-note{
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 140%;
                margin-top: 16px;
            }

            .done-btn{
                height: 56px;
                margin-top: 16px;
                background: linear-gradient(175.31deg, rgb(240, 170, 34) -8.91%, rgb(240, 88, 34) 99.52%) rgb(204, 139, 14);
            }
        }

        .cancel-button{
            margin-top: 16px;
            height: 56px;
            background: none;
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.6);
            border: none;
            box-shadow: none;
            width: 100%;
        }

    }
}