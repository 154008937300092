@import '../../styles/variables';

.mandate-success-page-container{
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(6, 58, 79, 1);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: center;
    align-items: center;

    .mandate-success-page{
        display: flex;
        max-width: 397px;
        min-height: 100px;
        padding: 100px 80px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        border-bottom: 1px solid rgba(180, 203, 213, 0.40);
        background: #F9FBFD;

        @media screen and (max-width: 767px) {
            margin: 40px 20px;
            padding: 50px 40px;
        }

        .info-text{
            font-size: 24px;
            line-height: 29px;
            color: rgba(26, 26, 26, 1);
            text-align: center;
            margin-top: 32px;
     
        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
        }

        .back-btn{
            margin-top: 32px;
            background: rgba(0, 184, 194, 0.1);
            color: rgba(0, 184, 194, 1);
            height: 56px;
            border-radius: 4px;
            font-family: Inter, sans-serif;
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            &:hover{
                color: rgba(0, 184, 194, 1);  
                background: rgba(0, 184, 194, 0.1);
            }
        }

    }
}